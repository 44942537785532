.photo-grid-wrapper {
    flex-basis: 800px;
    flex-grow: 1;
}

.photo-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-basis:800px;
    flex-grow: 1;
    padding: 20px;
}

.logo   {
    width: 400px;
}

.info-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding:23px 40px 10px  0px;
}

@media only screen and (max-width: 800px) {
    .photo-grid {
        flex-basis:auto;
        padding-bottom: 200px;
    }

}