.button-wrapper {
    display:flex;
    width: 105px;
    justify-content: space-between;

}

.button-wrapper input {
    width: 40px;
    text-align: center;
}

.button-wrapper button {
    color:#444;
}

.print-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
}