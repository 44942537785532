@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 2px;
  color: #292929;

}


.basketInfo {
  background:green;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
