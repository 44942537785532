.content-wrapper {
background:#f7f7f7;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

@media only screen and (max-width: 800px) {
    .content-wrapper {
        flex-direction: column;
    }
}