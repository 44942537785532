.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.container form {
    margin-top:30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container form input{
    padding:15px;
    border-radius: 3px;
    border:1px solid #d9d9d9;
    margin-right: 20px;
    width: 300px;
    max-width: 70%;

}
.container form  button{
    padding:15px;
    border-radius: 3px;
    border:0px;
    height: 100%;
    background-color: aliceblue;
    max-width: 25%;
}



.logo   {
    width: 400px;
    max-width: 90%;
    margin-top:-140px;
}

@media only screen and (max-width: 700px){
    .container form {
        flex-direction: column;
        gap:20px;
    }
    .container form button {
        max-width: unset;
        width: 90%;

    }
}