.basket-wrapper {
    width:400px;
    background-color: white;
    padding:15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.next-button {
    padding:10px;
    background-color: #086CFF;
    color:white;
    border-radius: 10px;
    border:0px;
    width: 100%;
}

.basket-warning {
    background-color: #ffffde;
    padding: 10px;
    font-size: 12px;
    margin-bottom: 10px;
    border-radius: 4px;
}
.basket-line-wrapper {
    display: flex;
    flex-direction: column;
}

.extend-button {
    display: none;
}

@media only screen and (max-width: 800px){
    .basket-wrapper {
        width:100%;
        position: fixed;
        height:auto;
        max-height: 200px;
        bottom:0px;
        overflow-y:scroll;
        border-top:1px solid #d4d4d4;
    }

    .basket-line-wrapper {
        display: none;
    }
    .basket-wrapper.extend-it {
        max-height:100vh;
    }

    .basket-line-wrapper.extended {
         display: flex;
        flex-direction: column;
     }


    .basket-title {
        display:none;
    }

    .extend-button {
        display: block;
        margin:0px;
    }
}
