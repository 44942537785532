.photo-tile {
    width: 30%;
    margin-bottom:10px;
    /*background-color:white;*/
    border-radius: 5px;


    display: flex;
    flex-direction: column;
    justify-content: center;



}

.photo-tile-title {
    text-transform: lowercase;
    padding-bottom: 12px;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
}
.preview-pic-wrapper {
    width: 100%;
    height:240px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.preview-pic {
    width: auto;
    height:100%;
    border-radius: 3px;
    box-shadow: 1px 0px 11px -3px rgba(0,0,0,0.75);

}
.preview-pic-magnified-wrapper {
    position: fixed;
    height: 100%;
    width: 100vw;
    top: 0px;
    left: 0px;
    background-color: rgb(0 0 0 / 80%);
}
.preview-pic-magnified-container {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 90%;
    margin: 10px auto;
    height:90%;
}
.preview-pic-magnified-container .close-button {
    margin-bottom: 10px;
    padding: 10px;
    background: white;
    border: 1px solid;
    border-radius: 3px;
}
.preview-pic-magnified-container img {
    height:100%;
    width:fit-content;
    margin:0 auto;
}
.selected-wrapper {
    display: flex;
}
.selected-label {
    font-size:12px;
    background-color:#f2f2f2;
    padding:10px;
    text-transform: lowercase;
    color:#7EA16B;
    margin-left:3px;
}
.button-wrapper {
    padding:10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.button-wrapper button {
    padding:10px;
    text-transform: lowercase;
    font-weight: bold;
    font-size: 12px;

    border:0px;
    color: white;
    border-radius: 2px;
}

.button-wrapper .add-button {
    background-color: #292929;
}

@media only screen and (max-width: 800px) {
    .content-wrapper {
        flex-direction: column;
    }

    .preview-pic {
        max-width: 100%;
    }
    .photo-tile {
        width: 48%;
    }
    .preview-pic-wrapper {
        width: 100%;
        height: auto;
        max-height: 160px;
        /* height: 160px; */
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .preview-pic-magnified-container img {
        width:100%;
        height:fit-content;

    }

}